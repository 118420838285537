// require('dotenv').config()
import React from 'react'
import Refund from "../Refund";
import "./settings.css"
import { FaCog } from "react-icons/fa";
import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../context/AuthProvider';
import { useCustomer } from '../../context/CustomerProvider';
import { useHistory, useLocation } from "react-router-dom"
import { Button, Alert, Switch, Space, Table, Tag, Modal, Input, Select, Tooltip, Dropdown, Menu, notification } from 'antd';
import { GiConfirmed } from 'react-icons/gi';
import { GrCircleAlert } from 'react-icons/gr';
import { EyeTwoTone, EyeInvisibleOutlined, CaretDownOutlined } from '@ant-design/icons';

const { Column, ColumnGroup } = Table;
const { TextArea } = Input;

export default function Settings() {

  const history = useHistory();
  const location = useLocation();

  const { currentUser, brandId, brands, setSelectedBrand, triggerBrand } = useAuth()

  const { addAgent, deleteAgent, getAgents, createBrand } = useCustomer()
  const { addTeam, deleteTeam, getTeam } = useCustomer()
  const { team, setTeam } = useCustomer()
  const { selectorTeam, setSelectorTeam, getTeam2 } = useCustomer()
  const { setAutomations, setFAQ, getAutomations, brandValues, stripeUsage, sendInvite, setBrandValues, stripeAccount, getStripeLink, stripeSubscribe, setNylas, addIntegration, getIntegrations, saveIntegration, deleteIntegration } = useCustomer()
  const { getKeys, sendApiKeys, gorgiasKeys, getGorgiasKeys, getApi, resetApi, trackingKey, getTrackingKey, addBrand, createApi, addClient, getClients, deleteClient } = useCustomer();

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  // const [name, setName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [inviteLink, setInviteLink] = useState('https://calendly.com/chirpish/demo');
  const [expiration, setExpiration] = useState('');

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const filteredBrands = brands?.filter((brand) =>
    brand.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [aiEnabled, setAiEnabled] = useState(false);
  const [automationsEnabled, setAutomationsEnabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select Brand');

  const [showCode, setShowCode] = useState(false);
  const [showCodeGorgias, setShowCodeGorgias] = useState(false);
  const [showCodeChirpish, setShowCodeChirpish] = useState(false);

  const [lock, setLock] = useState(false);
  const [aiLock, setAiLock] = useState(false);

  const [usersId, setUsersId] = useState("");

  useEffect(() => {
    console.log(`Current brand: ${brandId}`);
    if (brandId === null) {
      setLock(true);
      setAiLock(true);
    } else {
      setLock(false);
      setAiLock(false);
    }
  }, [brandId]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const api = await getApi();
        setChirpishApi(api);
      } catch (error) {
        console.log("Error setting API key.");
      }
    };

    fetchData();
  }, [currentUser, brandId]);


  const lockSettings = {
    pointerEvents: lock ? 'none' : 'auto',
    opacity: lock ? '50%' : '100%'
  }

  const aiLockSettings = {
    pointerEvents: aiLock ? 'none' : 'auto',
    opacity: aiLock ? '50%' : '100%'
  }

  // GET THE NYLAS CODE AND USE IT TO SAVE THE NYLAS DATA TO THE DATABASE FOR THE USER
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const nylasValue = params.get('nylas');
    if (nylasValue) {
      callSetNylas(nylasValue);
    }
  }, []);

  async function callSetNylas(code) {
    // Your async function logic here
    console.log("Retrieved Nylas code, setting it now..")
    try {
      const set = await setNylas(code);
      console.log("Successfully set Nylas!")
    } catch (error) {
      console.log("Error setting Nylas..")
    }
  }

  // const redirectToO = () => {
  //   history.push('/o');
  // };

  const onChange = (identifier, checked) => {
    console.log(`Switching ${identifier} to ${checked}`);
    set(identifier, checked)
  };

  async function set(identifier, value) {
    const callSetIdentifier = await setAutomations(identifier, value)

    // Update the local state with the new value
    setAutomations2((prevAutomations) => ({
      ...prevAutomations,
      [identifier]: value,
    }));
  }

  const handleBrandNameChange = (e) => {

    const inputBrandName = e.target.value;
    setBrandName(inputBrandName);
    console.log(inputBrandName);
  };



  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    // You can use a regular expression to validate the email format
    const isValid = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(inputEmail);
    setEmail(inputEmail);
    setIsEmailValid(isValid);
  };

  // useEffect(() => {
  //   console.log("getting team for selector on load")
  //   callTeam2();
  // }, [brandId]);

  // async function callTeam2() {
  //   try {
  //     const res = await getTeam2();
  //     console.log("ressdsdsds: ", res)
  //   } catch (error) {
  //     console.log("fell to an error?")
  //     console.log(error)
  //   }
  //   console.log("how could I not fall into here?")
  // }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [isModalOpenNew, setIsModalOpenNew] = useState(false);
  const [isModalOpen2New, setIsModalOpen2New] = useState(false);
  const [isBrandModalOpenNew, setIsBrandModalOpenNew] = useState(false);
  const [isCheckedNew, setIsCheckedNew] = useState(false);

  const [stripeMessage, setStripeMessage] = useState('Manage your stripe account..');
  const [stripeType, setStripeType] = useState('plan');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [gorgiasAdminEmail, setGorgiasAdminEmail] = useState("");
  const [gorgiasDomain, setGorgiasDomain] = useState("");
  const [gorgiasApiKey, setGorgiasApiKey] = useState("");
  const [authorizationKey, setAuthorizationKey] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showBrandModal = () => {
    setIsBrandModalOpen(true);
  }

  const showBrandModalNew = () => {
    setIsBrandModalOpenNew(true);
  }

  const toggleModal = () => {
    console.log("in here too")
    setIsModalVisible(!isModalVisible);
    setAiEnabled(false)
  };

  // const handleCreate = async () => {
  //   try {
  //     if (stripeType === 'billing') {
  //       // Redirect to google.com in a new window
  //       window.open('https://billing.stripe.com/p/login/3csaIj88Dcrn8j63cc', '_blank');
  //     } else {
  //       // Your existing subscription creation logic here

  //       // Retrieve the referral information from the global 'window' object
  //       const aff_data = window.LinkMink('pub_live_2foBDbORbBKHAbQqSJC4').getReferralData();

  //       // Set lm_data to null if it's undefined or null
  //       const lm_data = aff_data || null;

  //       const stripe = await stripeCreate(brandName, email, lm_data);
  //       console.log("Stripe: ", stripe)

  //       const subscribe = await stripeSubscribe(email);
  //       console.log("Subscribe: ", subscribe)

  //       console.log("Successfully created the user a Stripe account")

  //       // Successful
  //       setIsModalVisible(!isModalVisible);
  //       setAiEnabled(true); // Update the state immediately
  //       await setBrandValues([{ name: "ai", value: true }]);
  //     }
  //     console.log(lm_data);
  //   } catch (error) {
  //     // Handle unexpected errors
  //     console.error('Error creating subscription:', error);
  //     // You can display an error message to the user if needed
  //     setIsModalVisible(!isModalVisible);
  //     setAiEnabled(false);
  //   }
  // };

  const generateSubscriptionLink = async () => {
    try {
      const aff_data = window.LinkMink('pub_live_2foBDbORbBKHAbQqSJC4').getReferralData();
      const lm_data = aff_data || null;
      console.log("lm_data: ", lm_data);
      const stripe = await getStripeLink(brandId, lm_data)
      console.log(stripe);
      setStripeLink(stripe);
    } catch (error) {
      console.error(error);
    }
  }

  const redirectToStripe = async () => {
    window.open(stripeLink, '_blank');
    setAiEnabled(false);
    setIsModalVisible(false);
  };


  const getExpiration = () => {
    const expirationTime = new Date();
    // expiration is 24 hours from now
    expirationTime.setUTCHours(expirationTime.getUTCHours() + 24);
    // expirationTime.setUTCHours(expirationTime.getUTCHours());

    // convert to UTC timestamp into Unix value
    const timestamp = Math.floor(expirationTime.getTime() / 1000);
    return timestamp
  }

  const handleOk = async () => {
    try {
      const send = await sendInvite(brandId, emailAddress)
      console.log(send)
      setEmailAddress('')
      setIsModalOpen(false)
      openPopup('Invite Sent!.', `You have sent an invite link to ${emailAddress}`, <GiConfirmed />, '#D1FFBD')
    } catch (error) {
      console.error(error)
    }
  };


  const handleBrandModalOk = async () => {
    console.log(brandName);
    try {
      const response = await createBrand(brandName)
      console.log(response);

      if (response.success) {
        const trigger = await triggerBrand();
        console.log("Trigger brand update after new brand created: ", trigger)
        setLock(false);
        setAiLock(false);
        setBrandName('');
      }
      setIsBrandModalOpen(false);
      openPopup('Brand Created!.', `You have successfully created a brand with the name of ${brandName}.`, <GiConfirmed />, '#D1FFBD')
    } catch (error) {
      setIsBrandModalOpen(false);
      console.error(error);
    }
  }


  const handleBrandModalOkNew = async () => {
    try {
      const response = await addBrand(usersId);
      console.log(`add brand: `, response);

      if (response) {
        setIsBrandModalOpenNew(false);
        openPopup('Successfully added user to brand!', `You have successfully added the new user to ${brandName}.`, <GiConfirmed />, '#D1FFBD')
      } else {
        setIsBrandModalOpenNew(false);
        openPopup('Failed to add that user to brand. Likely invalid user ID. ', `The user can find their ID under "Manage team"`, <GiConfirmed />, '#FFCCCC')
      }
    } catch (error) {
      setIsBrandModalOpenNew(false);
      openPopup('Successfully added user to brand!', `You have successfully added the new user to ${brandName}.`, <GiConfirmed />, '#FFCCCC')
      console.error(error);
    }
  }


  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const setMaster = () => {
    localStorage.removeItem('user');
    setUser("Master")
  }

  const handleOk2 = async () => {
    setIsModalOpen2(false);
  };

  const handleBrandModalCancel = () => {
    setBrandName('');
    setIsBrandModalOpen(false);
  }

  const handleBrandModalCancelNew = () => {
    setIsBrandModalOpenNew(false);
  }

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // setName("")
  };

  const onChange2 = (value) => {
    console.log(`selected ${value}`);
    // SET TO SESSION VARIABLE
    localStorage.setItem('user', value);
    setUser(value)
  };

  const onSearch2 = (value) => {
    console.log('search:', value);
  };

  async function handleDelete(id) {
    console.log("clicked handleDelete with id: ", id)
    const res = await deleteTeam(id);
    callTeam();
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Role',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            if (tag === 'loser') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Manage',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          <a onClick={() => handleDelete(record.firebaseId)}>Delete</a>
        </Space>
      ),
    },
  ];


  async function callTeam() {
    try {
      const res = await getTeam();
      console.log("res: ", res)
    } catch (error) {
      console.log(error)
    }
  }


  const fetchIntegrations = async () => {
    try {
      // Call the asynchronous function
      const fetchedIntegrations = await getIntegrations();
      // console.log("Response: ", fetchedIntegrations)
      // console.log("argssss: ", args)

      // Combine the presetArgs with the fetchedIntegrations
      const updatedArgs = [...presetArgs, ...fetchedIntegrations];

      // Update the state with the combined array
      setArgs(updatedArgs);
    } catch (error) {
      console.error("Error fetching integrations:", error);
    }
  };


  useEffect(() => {
    // Call the asynchronous function
    fetchIntegrations();
  }, [brandId]);

  const [user, setUser] = useState("Master");

  const presetArgs = [
    {
      "id": "automations",
      "title": "Automations",
      "description": "This is for automating all the requests that don't take actions on behalf of your store. This can include edgecases, but these can be mimimized using policy.",
      "builtIn": true,
      "automation": false,
      "webhook": "https://google.com",
      "arguments": [] // {"arg": "dgdhrrdhdr", "value": "hftbfdbdfbfb"}
    },
    {
      "id": "refund",
      "title": "Refunds",
      "description": "Let Shopify refund order when it matches policy.",
      "builtIn": true,
      "automation": false,
      "webhook": "https://google.com",
      "arguments": [] // {"arg": "dgdhrrdhdr", "value": "hftbfdbdfbfb"}
    },
    {
      "id": "cancellation",
      "title": "Cancellation",
      "description": "Let Shopify cancel order when it matches policy.",
      "builtIn": true,
      "automation": false,
      "webhook": "https://google.com",
      "arguments": []
    },
    {
      "id": "tracking",
      "title": "Tracking",
      "description": "Automate inquiries that retrieve tracking information.",
      "builtIn": true,
      "automation": false,
      "webhook": "https://google.com",
      "arguments": []
    },
    {
      "id": "faq",
      "title": "FAQs",
      "description": "Automate FAQ inquiries based on FAQ box below.",
      "builtIn": true,
      "automation": false,
      "webhook": "https://google.com",
      "arguments": []
    },
    {
      "id": "modify",
      "title": "Modify",
      "description": "Let Shopify modify order name, email, and shipping address.",
      "builtIn": true,
      "automation": false,
      "webhook": "https://google.com",
      "arguments": []
    },
  ]
  const [args, setArgs] = useState(presetArgs);

  const [brand, setBrand] = useState("");
  const [policy, setPolicy] = useState("");
  const [faqNew, setFaqNew] = useState("");
  const [tone, setTone] = useState("");
  const [testMode, setTestMode] = useState(false);



  const handleSaveButtonClick = async (boxName) => {
    // Perform save operation for the corresponding box
    switch (boxName) {
      case 'policy':
        console.log("Saving policy: ", policy)
        setFAQ("policy", policy)
        openPopup('Policy Settings Saved.', 'Policy has been updated!', <GiConfirmed />, '#D1FFBD')
        break;
      case 'faqs':
        console.log("Saving new faq: ", faqNew)
        setFAQ("faqs", faqNew)
        openPopup('FAQs Settings Saved.', 'FAQs has been updated!', <GiConfirmed />, '#D1FFBD')
        break;
      case 'tone':
        console.log("Saving tone: ", tone)
        setFAQ("tone", tone)
        openPopup('Tone Settings Saved.', 'Tone has been updated!', <GiConfirmed />, '#D1FFBD')
        break;
      case 'name':
        console.log("Saving brand: ", brand)
        setFAQ("name", brand)
        openPopup('Brand Settings Saved.', 'Brand name has been updated!', <GiConfirmed />, '#D1FFBD')
        break;
      default:
        break;
    }
  };


  const [automations, setAutomations2] = useState({});


  const [stripeLink, setStripeLink] = useState('');

  // const handleAiToggle = async (checked) => {
  //   // console.log("handle AI toggle ", checked);
  //   // setAiEnabled(checked); // Update the state immediately

  //   if (checked) { // true
  //     // Call your async functions here when AI is turned ON
  //     try {
  //       // Make sure the user has a Stripe account to make sure we charge them, and if not then
  //       // pull up a screen that makes them create an account
  //       const create = await stripeAccount();
  //       console.log("Retrieved Stripe account status: ", create);
  //       console.log("Create Message: ", create.message)
  //       console.log("Create Type: ", create.type)

  //       setStripeMessage(create?.message || "Manage your Stripe account..")
  //       setStripeType(create?.type || "error")

  //       if (create.status === true) {
  //         console.log("Stripe is already set and we're ready to roll!");
  //         setAiEnabled(true); // Update the state when the account is successfully created
  //         openPopup('AI Enabled.', 'AI is set to Active', <GrCircleAlert />, '#EEE')
  //         await setBrandValues([{ name: "ai", value: true }]);

  //       } else {
  //         // Send a popup that makes them create a Stripe account
  //         console.log("Send a popup to create the Stripe account");
  //         setAiEnabled(false);
  //         await setBrandValues([{ name: "ai", value: false }]);
  //         setIsModalVisible(!isModalVisible);

  //         if (create.type === 'billing') {
  //           setStripeLink('https://billing.stripe.com/p/login/3csaIj88Dcrn8j63cc')
  //         } else if (create.type === 'plan') {
  //           const subscriptionLink = await generateSubscriptionLink();
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error calling async functions:', error);
  //       setAiEnabled(false);
  //       await setBrandValues([{ name: "ai", value: false }]);
  //     }
  //   } else {
  //     // If the user toggles AI OFF, you can set aiEnabled to false immediately
  //     openPopup('AI Disabled.', 'AI is set to Inactive', <GrCircleAlert />, '#EEE')
  //     setAiEnabled(false);
  //     await setBrandValues([{ name: "ai", value: false }]);
  //   }
  // };



  const handleAutomationsToggle = async (checked) => {
    console.log("handle automations toggle ", checked);

    // Conditionally render specific popup
    if (checked) {
      openPopup('Automations Enabled.', 'Automations is set to Active', <GrCircleAlert />, '#EEE')
    } else {
      openPopup('Automations Disabled.', 'Automations is set to Inactive', <GrCircleAlert />, '#EEE')
    }

    // Check if automationsEnabled is different from checked
    // if (automationsEnabled !== checked) {
    setAutomationsEnabled(checked); // Update the state immediately

    try {
      // Update the "automation" field only if there is a change
      await setBrandValues([{ name: "automation", value: checked }]);
    } catch (error) {
      console.log("Error trying to send automation status");
      console.log("Error: ", error);
    }
    // }
  };



  // Define an async function to call brandValues
  const ai = async () => {
    if (brandId) {
      try {
        const data = await brandValues(['automation']); // they'll exist or they won't

        // Handle the data as needed, e.g., set it to state
        console.log('AI Data fetched: ', data);

        if (data.automation !== undefined && data.automation !== null) {
          console.log("Set automation: ", data.automation)
          handleAutomationsToggle(data.automation);
        } else {
          handleAutomationsToggle(false);
        }
      } catch (error) {
        console.error('Error fetching AI data:', error);
      }
    }
  };


  useEffect(() => {
    ai();

    fetchKeys();
    fetchGorgiasKeys();
    // Call the getAutomations function here to fetch the automation values
    getAutomations()
      .then((data) => {
        // console.log("getAutomations useEffect in settings is: ", data)
        setAutomations2(data)
      })
      .catch((error) => console.error('Error occurred while getting automations:', error));
  }, [brandId]);


  useEffect(() => {
    async function fetchData() {
      try {
        const values = await brandValues(['faqs', 'name', 'policy', 'tone', 'arguments', 'testmode', 'automation']);
        console.log("Retrieved brand values: ", values);

        setPolicy(values.policy);
        setFaqNew(values.faqs);
        setTone(values.tone);
        setBrand(values.name);
        setTestMode(values.testmode);

        // Update the automation value in the args state
        setArgs(prevArgs => {
          const updatedArgs = prevArgs.map(arg => {
            if (arg.id === 'automations') {
              return {
                ...arg,
                automation: values.automation
              };
            }
            return arg;
          });
          return updatedArgs;
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    }

    if (brandId !== null) {
      fetchData();
    }
    // fetchData();
  }, [brandId]);


  const [passwordVisible, setPasswordVisible] = useState(false);

  function isObjectEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return true; // Object has at least one key, so it's not empty
      }
    }
    return false; // Object has no keys, so it's empty
  }

  const fetchKeys = async () => {
    // Call your function here to fetch the keys
    // console.log("Fetching keys..")
    const theKeys = await getKeys();
    // Update the state variables with the fetched keys
    setShopifyName(theKeys.shopify_name || '');
    setShopifyApiKey(theKeys.shopify_api_key || '');
    setShopifyPrivateKey(theKeys.shopify_private_key || '');
    setShopifyAdmin(theKeys.shopify_admin || '');

    const hasKeys = isObjectEmpty(theKeys);
    if (hasKeys) {
      setAiLock(false);
    } else {
      setAiLock(true);
    }
  };


  const fetchGorgiasKeys = async () => {
    // Call your function here to fetch the keys
    const keys = await getGorgiasKeys();
    // Update the state variables with the fetched keys
    setGorgiasName(keys.gorgias_name || '');
    setGorgiasEmail(keys.gorgias_email || '');
    setGorgiasApi(keys.gorgias_api || '');

    const hasKeys = isObjectEmpty(keys);
    if (hasKeys) {
      setAiLock(false);
    } else {
      setAiLock(true);
    }
  };

  const handleShowCodeClick = async () => {
    // await fetchKeys();
    setShowCode(true);
  };

  const handleShowCodeClickGorgias = async () => {
    setShowCodeGorgias(true);
  };

  const handleShowCodeClickChirpish = async () => {
    setShowCodeChirpish(true);
  };

  const saveKeys = async () => {
    try {
      const data = {
        shopify_name: shopifyName,
        shopify_api_key: shopifyApiKey,
        shopify_private_key: shopifyPrivateKey,
        shopify_admin: shopifyAdmin,
      };

      await sendApiKeys(data);
      openPopup('API Keys Saved.', 'API Keys Added / Updated!', <GiConfirmed />, '#D1FFBD')
      setAiLock(false);
      setShowCode(false);
    } catch (error) {
      openPopup('Saved Failed.', 'Failed Saving API Keys.', <GiConfirmed />, '#D1FFBD')
      console.log("Error sending API Keys.. ", error)
    }
  };


  const saveKeysGorgias = async () => {
    try {
      const data = {
        gorgias_name: gorgiasName,
        gorgias_email: gorgiasEmail,
        gorgias_api: gorgiasApi,
      };

      await gorgiasKeys(data);
      openPopup('API Keys Saved.', 'API Keys Added / Updated!', <GiConfirmed />, '#D1FFBD')
      setAiLock(false);
      setShowCodeGorgias(false);
    } catch (error) {
      openPopup('Saved Failed.', 'Failed Saving API Keys.', <GiConfirmed />, '#D1FFBD')
      console.log("Error sending API Keys.. ", error)
    }
  };




  const [shopifyName, setShopifyName] = useState('');
  const [shopifyApiKey, setShopifyApiKey] = useState('');
  const [shopifyPrivateKey, setShopifyPrivateKey] = useState('');
  const [shopifyAdmin, setShopifyAdmin] = useState('');
  const [supportEmail, setSupportEmail] = useState('');
  const [brandName, setBrandName] = useState('');
  const [agentName, setAgentName] = useState('');

  const [gorgiasName, setGorgiasName] = useState('');
  const [gorgiasEmail, setGorgiasEmail] = useState('');
  const [gorgiasApi, setGorgiasApi] = useState('');
  const [chirpishApi, setChirpishApi] = useState('');

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [saveButtonDisabledGorgias, setSaveButtonDisabledGorgias] = useState(true);

  useEffect(() => {
    const areAllInputsFilled =
      shopifyName.trim() !== '' &&
      shopifyApiKey.trim() !== '' &&
      shopifyPrivateKey.trim() !== '' &&
      shopifyAdmin.trim() !== '';

    setSaveButtonDisabled(!areAllInputsFilled);
  }, [shopifyName, shopifyApiKey, shopifyPrivateKey, shopifyAdmin, brandId]);


  // useEffect(() => {
  //   const areAllInputsFilled2 =
  //     gorgiasName.trim() !== '' &&
  //     gorgiasEmail.trim() !== '' &&
  //     gorgiasApi.trim() !== '' &&

  //   setSaveButtonDisabledGorgias(!areAllInputsFilled2);
  // }, [gorgiasName, gorgiasEmail, gorgiasApi, brandId]);

  useEffect(() => {
    const areAllInputsFilled2 =
      gorgiasName.trim() !== '' &&
      gorgiasEmail.trim() !== '' &&
      gorgiasApi.trim() !== '';

    setSaveButtonDisabledGorgias(!areAllInputsFilled2);
  }, [gorgiasName, gorgiasEmail, gorgiasApi, brandId]);


  const [currentBrand, setCurrentBrand] = useState({ id: null, name: null });

  useEffect(() => {
    // Find a brand with a matching id
    const matchedBrand = brands.find(brand => brand.id === brandId);
    // console.log("matched brands: ", matchedBrand)
    if (matchedBrand) {
      setCurrentBrand(matchedBrand);
      setSelectedOption(matchedBrand.name);
    } else {
      setCurrentBrand({ id: null, name: null });
    }
  }, [brandId, brands]);

  const handleChange = async (value) => {
    console.log("The brands are: ", brands)
    console.log(`selected ${value}`);
    await setSelectedBrand(value)
    setShowCode(false);
    setShowCodeGorgias(false);
    setShowCodeChirpish(false);
    setShowCodeTrack(false);
  };


  // Define state variables for each switch
  const [refundEnabled, setRefundEnabled] = useState(false);
  const [trackingEnabled, setTrackingEnabled] = useState(false);
  const [faqsEnabled, setFaqsEnabled] = useState(false);
  const [modifyEnabled, setModifyEnabled] = useState(false);
  const [cancellationsEnabled, setCancellationsEnabled] = useState(false);


  useEffect(() => {
    // Update automation values based on state values
    // console.log("Updating the automation values")
    // console.log("args: ", args)

    const updatedPresets = args.map(preset => {
      switch (preset.title) {
        case "Automations":
          return { ...preset, automation: automationsEnabled };
        case "Refunds":
          return { ...preset, automation: refundEnabled };
        case "Cancellation":
          return { ...preset, automation: cancellationsEnabled };
        case "Tracking":
          return { ...preset, automation: trackingEnabled };
        case "Modify":
          return { ...preset, automation: modifyEnabled };
        case "FAQs":
          return { ...preset, automation: faqsEnabled };
        default:
          return preset;
      }
    });

    // console.log("updatedPresets : ", updatedPresets)
    setArgs(updatedPresets);
  }, [refundEnabled, cancellationsEnabled, trackingEnabled, modifyEnabled, faqsEnabled]);


  // Function to handle switch changes with a key
  const handleSwitchChange = async (key, newValue) => {
    // Update the state variable based on the key
    console.log("handleSwitchChange", key, newValue);
    switch (key) {
      case 'automation':
        handleAutomationsToggle(newValue);
        break;
      case 'refund':
        setRefundEnabled(newValue);
        break;
      case 'tracking':
        setTrackingEnabled(newValue);
        break;
      case 'faqs':
        setFaqsEnabled(newValue);
        break;
      case 'modify':
        setModifyEnabled(newValue);
        break;
      case 'cancellations':
        setCancellationsEnabled(newValue);
        break;
      default:
        const updatedArgs = [...args];
        console.log("new value is: ", newValue);
        console.log("Updated arg before: ", updatedArgs[key]);
        updatedArgs[key].automation = newValue;
        console.log("Updated arg after: ", updatedArgs[key]);
        handleSaveButton(updatedArgs[key]);
        setArgs(updatedArgs);
        break;
    }

    // Update the brandValues object in Firestore
    const updatedBrandValues = {
      automationValues: {
        refund: key === 'refund' ? newValue : refundEnabled,
        tracking: key === 'tracking' ? newValue : trackingEnabled,
        faq: key === 'faqs' ? newValue : faqsEnabled,
        modify: key === 'modify' ? newValue : modifyEnabled,
        cancellation: key === 'cancellations' ? newValue : cancellationsEnabled,
      },
    };
    await setBrandValues([{ name: "automationValues", value: updatedBrandValues.automationValues }]);
  };


  useEffect(() => {
    // Fetch brandValues data and set the state variables
    const fetchData = async () => {
      const data = await brandValues(['automationValues']);
      // console.log("Data from automation values: ", data);

      if (data && data.automationValues) {
        // console.log("automation values 1")
        const { refund, tracking, faq, cancellation, modify } = data.automationValues;

        // Set state variables with default values of false if the properties don't exist
        setRefundEnabled(refund !== undefined ? refund : false);
        setTrackingEnabled(tracking !== undefined ? tracking : false);
        setFaqsEnabled(faq !== undefined ? faq : false);
        setModifyEnabled(modify !== undefined ? modify : false);
        setCancellationsEnabled(cancellation !== undefined ? cancellation : false);
      } else {
        console.log("automation values 2")
        // If data.automationValues doesn't exist, set all state variables to false
        setRefundEnabled(false);
        setTrackingEnabled(false);
        setFaqsEnabled(false);
        setModifyEnabled(false);
        setCancellationsEnabled(false);
      }
    };

    if (brandId !== null) {
      fetchData();
    }
    // fetchData();
  }, [brandId]);



  const openPopup = (message, description, icon, bg) => {
    notification.open({
      message,
      description,
      icon,
      placement: 'topRight',
      style: { 'background-color': bg },
      duration: 4,
    })
  }


  const handleToggleArguments = (index) => {
    setSelectedOption2(index);
  };


  const [selectedOption2, setSelectedOption2] = useState(0);

  // Handle delete for the already existing automations arguments
  const handleDeleteArgument = (automationIndex, argumentIndex) => {
    console.log("handleDeleteArgument")
    console.log("automationIndex", automationIndex)
    console.log("argumentIndex", argumentIndex)

    // Log args at the specified automationIndex
    const argsAtIndex = args[automationIndex];
    console.log("args at automationIndex:", argsAtIndex);

    // Log the argument object at the specified argumentIndex
    const argumentObject = argsAtIndex.arguments[argumentIndex];
    console.log("argument object:", argumentObject);

    setArgs(prevArgs => {
      const updatedArgs = [...prevArgs];
      const updatedArguments = [...updatedArgs[automationIndex].arguments];
      updatedArguments.splice(argumentIndex, 1);
      updatedArgs[automationIndex] = { ...updatedArgs[automationIndex], arguments: updatedArguments };
      return updatedArgs;
    });
  };




  const [newIntegration, setNewIntegration] = useState({
    name: '',
    description: '',
    builtIn: false,
    automation: true,
    webhook: '',
    arguments: [],
  });

  const [keyInput, setKeyInput] = useState('');
  const [valueInput, setValueInput] = useState('');


  // Handle adding argument objects
  const handleAdd = () => {
    // Create a new argument object
    const newArgument = {
      arg: keyInput,
      value: valueInput,
    };

    // Update the state by appending the new argument to the existing arguments array
    setNewIntegration(prevIntegration => ({
      ...prevIntegration,
      arguments: [...prevIntegration.arguments, newArgument],
    }));

    // Optionally, you can clear the key and value inputs
    setKeyInput('');
    setValueInput('');
  };


  // Handle adding argument objects
  const handleAddArguments = (index) => {
    const newArgument = {
      arg: keyInput,
      value: valueInput,
    };

    setArgs(prevArgs => {
      const updatedArgs = [...prevArgs];
      const updatedArguments = [...updatedArgs[index].arguments, newArgument];
      updatedArgs[index] = { ...updatedArgs[index], arguments: updatedArguments };
      return updatedArgs;
    });

    // Optionally, you can clear the key and value inputs
    setKeyInput('');
    setValueInput('');
  };


  // Handle deleting
  const handleDeleteArg = (argumentIndex) => {
    setNewIntegration(prevIntegration => {
      const updatedArguments = [...prevIntegration.arguments];
      updatedArguments.splice(argumentIndex, 1);
      return { ...prevIntegration, arguments: updatedArguments };
    });
  };


  // Change the toggle of whether the automation should be on or not
  const handleToggleAutomation = (index, value, automation) => {
    console.log("handleToggleAutomation ", index, value, automation);
    // console.log("args: ", args)

    if ((index >= 0) && (index < 6)) {
      if (index === 0) {
        handleSwitchChange('automation', value)
      } else if (index === 1) {
        handleSwitchChange('refund', value)
      } else if (index === 2) {
        handleSwitchChange('cancellations', value)
      } else if (index === 3) {
        handleSwitchChange('tracking', value)
      } else if (index === 4) {
        handleSwitchChange('faqs', value)
      } else if (index === 5) {
        handleSwitchChange('modify', value)
      }
    } else {
      console.log("About to handleSwitchChange");
      handleSwitchChange(index, value);
    }

    setArgs((prevArgs) => {
      const updatedArgs = [...prevArgs];
      updatedArgs[index].automation = !updatedArgs[index].automation;
      return updatedArgs;
    });
  };


  const handleAddIntegration = async () => {
    // Your logic for handling the button press goes here
    // For example, you can submit the form or perform any other action
    console.log("Add Integration button pressed!: ", newIntegration);

    // Generate a new UUID for the ID of the newIntegration object
    const newId = uuidv4();

    // Set the UUID as the ID for the newIntegration object
    const newIntegrationWithId = {
      ...newIntegration,
      id: newId
    };


    try {
      const response = await addIntegration(newIntegrationWithId);
      console.log("Adding integration.. ", response)
      if (response.success === true) {
        // Append the new integration to the existing array
        openPopup('Added Integration.', 'Successfully added new integration.', <GiConfirmed />, '#D1FFBD')
        setArgs(prevArgs => [...prevArgs, newIntegrationWithId]);
      }
      // await fetchIntegrations();
    } catch (error) {
      console.log("Error trying to write integration: ", error)
    }
  };


  const handleSaveButton = async (automation) => {
    try {
      // Use the "id" from the clicked object
      const { id } = automation;
      console.log(`Save button clicked for object with ID: ${id}`);

      // Find the object in the 'args' array that matches the provided 'id'
      const matchingObject = args.find(obj => obj.id === id);

      if (matchingObject) {
        // Handle the matching object as needed
        console.log(`Save button clicked for object with ID: ${id}`);
        console.log('Matching Object:', matchingObject);
        await saveIntegration(matchingObject, id);
        openPopup('Saved Integration.', 'Successfully saved integration.', <GiConfirmed />, '#D1FFBD')
        // Perform additional logic or update the object as needed
      } else {
        console.log(`No object found with ID: ${id}`);
      }
      // You can perform additional logic or save the data as needed
    } catch (error) {
      console.log("Error: ", error)
    }
  };


  const handleDeleteButton = async (automation) => {
    try {
      // Use the "id" from the clicked object
      const { id } = automation;
      console.log(`Delete button clicked for object with ID: ${id}`);

      // Call the function to delete the corresponding document from Firestore
      const deleteResponse = await deleteIntegration(id);

      if (deleteResponse.success) {
        // Find the index of the object with the matching ID in 'args'
        const indexToDelete = args.findIndex(obj => obj.id === id);

        if (indexToDelete !== -1) {
          // Remove the object from 'args' array
          const updatedArgs = [...args];
          updatedArgs.splice(indexToDelete, 1);
          setArgs(updatedArgs);

          openPopup('Deleted Integration.', 'Successfully deleted integration.', <GiConfirmed />, '#D1FFBD')
          console.log(`Object with ID ${id} deleted from args array`);
        } else {
          console.log(`No object found with ID: ${id}`);
        }
      } else {
        console.error(`Error deleting integration with ID: ${id}`);
      }
    } catch (error) {
      console.error("Error handling delete button:", error);
    }
  }


  const saveChirpishApi = async () => {
    try {
      const response = await createApi(gorgiasDomain, gorgiasAdminEmail, gorgiasApiKey);
      if (response) {
        setAuthorizationKey(response);
      }
    } catch (error) {
      console.error('Error creating Gorgias API key:', error);
    }
  };



  const [showCodeTrack, setShowCodeTrack] = useState(false);
  const [trackKey, setTrackKey] = useState(''); // UseState for your API key
  // const [trackValue, setTrackValue] = useState(''); // Value for your API key input


  const handleShowCodeClickTrack = () => {
    setShowCodeTrack(true);
  };


  const saveKeysTrack = async () => {
    // Your save keys function here
    try {
      await trackingKey(trackKey);
      openPopup('API Keys Saved.', 'API Keys Added / Updated!', <GiConfirmed />, '#D1FFBD')
      setAiLock(false);
      setShowCodeTrack(false);
    } catch (error) {
      openPopup('Saved Failed.', 'Failed Saving API Keys.', <GiConfirmed />, '#D1FFBD')
      console.log("Error sending API Keys.. ", error)
    }
  };


  useEffect(() => {
    const fetchData3 = async () => {
      try {
        const tracking = await getTrackingKey();
        if (tracking) {
          setTrackKey(tracking);
        } else {
          setTrackKey("");
        }
      } catch (error) {
        console.log("Error setting API key.");
      }
    };

    fetchData3();
  }, [currentUser, brandId]);



  const handleToggleTestMode = () => {
    // Update testMode in the database (assuming you have a function for this)
    setFAQ("testmode", !testMode)

    // Update testMode in the local state
    setTestMode(prevTestMode => !prevTestMode);
  };


  const [clients, setClients] = useState([]);
  const [newClientName, setNewClientName] = useState('');
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [editingClientId, setEditingClientId] = useState(null);


  const handleCreateClient = async () => {
    if (newClientName.trim() !== '') {
      const newClient = {
        id: Date.now().toString(),
        name: newClientName,
        brandIds: [],
      };

      const response = await addClient(newClient);
      console.log("Response from adding new client: ", response);

      if (response.success === true) {
        setClients([...clients, newClient]);
        setNewClientName('');
        setEditingClientId(newClient.id); // Automatically set the new client to edit mode
      } else {
        alert("Error creating new client!");
      }
    }
  };


  const handleAddBrandsToClient = async (clientId) => {
    const updatedClient = clients.find((client) => client.id === clientId);
    if (updatedClient) {
      updatedClient.brandIds = [...updatedClient.brandIds, ...selectedBrands];
      const response = await addClient(updatedClient);
      console.log("Response from adding brands to client: ", response);

      if (response.success === true) {
        setClients(
          clients.map((client) => {
            if (client.id === clientId) {
              return updatedClient;
            }
            return client;
          })
        );
        setSelectedBrands([]);
      }
    }
  };

  const handleChange2 = (selectedValues) => {
    console.log("Selected values are: ", selectedValues);
    setSelectedBrands(selectedValues);
  };

  const handleEditClient = (clientId) => {
    setEditingClientId(clientId);
  };


  const handleSaveClient = async (clientId) => {
    const updatedClient = clients.find((client) => client.id === clientId);
    if (updatedClient) {
      const response = await addClient(updatedClient);
      console.log("Response from saving client: ", response);

      if (response.success === true) {
        setClients(
          clients.map((client) => {
            if (client.id === clientId) {
              return updatedClient;
            }
            return client;
          })
        );
        console.log("Clients are: ", clients);
        setEditingClientId(null);
      }
    }
  };


  const handleRemoveBrandFromClient = async (clientId, brandId) => {
    const updatedClient = clients.find((client) => client.id === clientId);
    if (updatedClient) {
      updatedClient.brandIds = updatedClient.brandIds.filter((id) => id !== brandId);
      const response = await addClient(updatedClient);
      console.log("Response from removing brand from client: ", response);

      if (response.success === true) {
        setClients(
          clients.map((client) => {
            if (client.id === clientId) {
              return updatedClient;
            }
            return client;
          })
        );
      }
    }
  };

  const handleDeleteClient = async (clientId) => {
    setClients(clients.filter((client) => client.id !== clientId));
    setEditingClientId(null);

    const response = await deleteClient(clientId);
    console.log("Response from deleting client: ", response);
  };

  const getBrandName = (brandId) => {
    const brand = brands.find((brand) => brand.id === brandId);
    return brand ? brand.name : '';
  };


  useEffect(() => {
    // Fetch clients from the server when the component mounts
    fetchClients();
  }, []);


  const fetchClients = async () => {
    try {
      const response = await getClients();
      // console.log(`Fetched clients: `, response);
      setClients(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };





  return (
    <>
      <div className='settings-container'>

        {/* <h1>Onboarding</h1> */}
        {/* <br></br> */}
        {/* <p className="gray">If you want to rewatch the onboarding again you can access it <a href="https://youtu.be/KJBwZtIo4xo" className="hyperlink" target="_blank">here</a>. To book a call with our team click <a href="https://calendly.com/chirpish/demo" className="hyperlink" target="_blank">here</a>.</p> */}
        <br></br>
        <p className="gray">For reference, your user ID is <b>{currentUser.uid}</b>. Ths is the ID to use when adding a user to a brand.</p>

        <div className='switch'>
          <p>Currently using: {currentBrand.name}</p>
          <p>Brand ID: {currentBrand.id}</p>

          {brands ? (
            <>
              <Select
                defaultValue={"Select brand"}
                style={{ width: 250, marginTop: '10px' }}
                value={selectedOption}
                onChange={handleChange}
                options={filteredBrands.map(brand => ({ value: brand.id, label: brand.name }))}
              />
              <Input
                placeholder="Search brands"
                style={{ width: 200, marginTop: '10px', marginLeft: '10px' }}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </>
          ) : null}
        </div>


        <Button className='add' onClick={showBrandModal}>
          Create a new brand
        </Button>

        <Modal title="Create a new Brand" open={isBrandModalOpen} onOk={handleBrandModalOk} onCancel={handleBrandModalCancel}>
          <Input placeholder="Enter your brand's name" type="text" onChange={(e) => handleBrandNameChange(e)} value={brandName}></Input>
        </Modal>

        <Button className='add' onClick={showBrandModalNew}>
          Add a user to {currentBrand.name}
        </Button>

        <Modal title="Add a user to this brand" open={isBrandModalOpenNew} onOk={handleBrandModalOkNew} onCancel={handleBrandModalCancelNew}>
          <Input
            placeholder="Enter the user ID you'd like to add."
            type="text"
            onChange={(e) => setUsersId(e.target.value)}
            value={usersId}
          />
        </Modal>

        {/* <div className='team'>
          <h1>Manage team</h1>
          
          <Button className='add' onClick={showModal}>
            Invite team member
          </Button>

          <Modal title="Add to team" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Input placeholder='Enter Email Address' type="text" id="name" value={emailAddress} onChange={(event) => setEmailAddress(event.target.value)} />
          </Modal>
          <Modal
            title={stripeType === 'billing' ? 'Manage your Subscription' : 'Start your subscription'}
            open={isModalVisible}
            onCancel={toggleModal}
            footer={[
              <Button key="create" type="primary" onClick={redirectToStripe}>
                Proceed
              </Button>,
              <Button key="close" onClick={toggleModal}>
                Close
              </Button>,
            ]}
          >
            {stripeType === 'billing' ? (
              <>
                <p>Your free trial of Chirpish AI over. To continue, simply add a payment method to your Stripe account and the billing will start tracking.</p>
                <br></br>
                <p>Clicking 'Proceed' will direct you to a Stripe page that will give you access to your account.</p>
                <br></br>
                <p>If you need help please reach out to our team in the bottom right and we'll respond ASAP.</p>
                <br />
              </>
            ) : (
              <>
                <p>Add a subscription to turn on AI for your brand. Only $0.33/message generated, no surprises.</p>
              </>
            )}
          </Modal>

        </div> */}

        <div className='selectors'>
          {/* <h1>AI</h1>
          <p className='margin'><i>Please read information below.</i></p>
          <Switch
            className='switchh'
            style={{ ...aiLockSettings, marginRight: "5px" }}
            checkedChildren="AI ON"
            unCheckedChildren="AI OFF"
            checked={aiEnabled}
            onChange={handleAiToggle}
          />
          <p>AI without automations will generate the best response it can with the given information and should be ready to send in 1-click.
            It will NOT try to perform functionality or automatically send the response to the customer.
          </p> */}

          <h1>Automation</h1>
          {/* <p className='margin'><i>Please read information below.</i></p> */}
          <p>Automations give the AI permissions to perform actions on your behalf through any of your integrated apps and gives it permissions to automatically send emails.
            AI is not perfect and can make mistakes. We are not responsible for repercussions from anything done by the AI.
          </p>


          <div className='automations-section'>
            {selectedOption2 !== -1 ? (
              <button className='super-edit' onClick={() => handleToggleArguments(-1)}>
                Add new integration
              </button>
            ) : (
              <div className='super-edit-2'>
                <Input
                  type="text"
                  className='inputtt'
                  value={newIntegration.title}
                  placeholder="Title"
                  onChange={(e) => {
                    const updatedTitle = e.target.value.replace(/[^a-zA-Z_]/g, ''); // Remove characters other than letters and underscores
                    setNewIntegration({ ...newIntegration, title: updatedTitle });
                  }}
                />
                <Input
                  type="text"
                  className='inputtt'
                  value={newIntegration.description}
                  placeholder="Description"
                  onChange={(e) => {
                    const updatedDescription = e.target.value.slice(0, 70); // Limit the description to 70 characters
                    setNewIntegration({ ...newIntegration, description: updatedDescription });
                  }}
                />
                <Input
                  type="text"
                  className='inputtt'
                  value={newIntegration.webhook}
                  placeholder="Zapier Webhook"
                  onChange={(e) => setNewIntegration({ ...newIntegration, webhook: e.target.value })}
                />

                <div>
                  {newIntegration.arguments.map((arg, argIndex) => (
                    <div key={argIndex}>
                      <Input
                        type="text"
                        className='texttt'
                        value={arg.arg}
                        placeholder="Key"
                        onChange={(e) => {
                          const updatedValue = e.target.value.replace(/[^a-zA-Z_]/g, ''); // Remove characters other than letters and underscores
                          setNewIntegration(prevIntegration => {
                            const updatedArgs = [...prevIntegration.arguments];
                            updatedArgs[argIndex].arg = updatedValue;
                            return {
                              ...prevIntegration,
                              arguments: updatedArgs,
                            };
                          });
                        }}
                      />
                      <Input
                        type="text"
                        className='texttt'
                        value={arg.value}
                        placeholder="Value"
                        onChange={(e) => {
                          setNewIntegration(prevIntegration => {
                            const updatedArgs = [...prevIntegration.arguments];
                            updatedArgs[argIndex].value = e.target.value;
                            return {
                              ...prevIntegration,
                              arguments: updatedArgs,
                            };
                          });
                        }}
                      />
                      <Button onClick={() => handleDeleteArg(argIndex)} type="danger">
                        Delete
                      </Button>
                    </div>
                  ))}
                </div>


                <div>
                  <Input
                    type="text"
                    className='texttt'
                    value={keyInput}
                    placeholder="Key"
                    onChange={(e) => {
                      const updatedValue = e.target.value.replace(/[^a-zA-Z_]/g, ''); // Remove characters other than letters and underscores
                      setKeyInput(updatedValue);
                    }}
                  />
                  <Input
                    type="text"
                    className='texttt'
                    value={valueInput}
                    placeholder="Value"
                    onChange={(e) => setValueInput(e.target.value)}
                  />
                  <Button onClick={handleAdd} type="primary" disabled={keyInput === "" || valueInput === ""}>+</Button>
                </div>

                <Button className='margin-button' onClick={handleAddIntegration} type="primary" disabled={newIntegration.title === "" || newIntegration.description === "" || newIntegration.webhook === ""}>
                  Add Integration
                </Button>
              </div>
            )}
          </div>





          <div className='automations-section'>
            {args.map((automation, index) => (
              <div key={index} className="automation-item" style={{ opacity: (index === 0 || automationsEnabled) ? 1 : 0.70 }}>
                {/* <p>{index}</p> */}
                <div className="content">
                  <div className={`all-in-line ${index !== selectedOption2 ? 'disabled-option' : ''}`} onClick={() => handleToggleArguments(index)}>
                    <Switch
                      style={lockSettings}
                      checkedChildren=""
                      unCheckedChildren=""
                      checked={automation.automation}
                      onChange={(value) => handleToggleAutomation(index, value, automation)}
                    />
                    <div className='the-text-body'>
                      <div className='left-of-body'>
                        <h4 className='titleee'>{automation.title}</h4>
                        <p className='paraaa'>{automation.description}</p>
                      </div>
                      {automation.builtIn === false && (
                        <div className='right-of-body'>
                          <CaretDownOutlined></CaretDownOutlined>
                        </div>
                      )}
                    </div>
                  </div>

                  {selectedOption2 === index && !automation.builtIn && (
                    <>
                      <div>
                        <Input
                          className='inputtt'
                          type="text"
                          value={automation.webhook || ''}
                          placeholder="Zapier Webhook URL"
                          onChange={(e) => {
                            const updatedArgs = [...args];
                            updatedArgs[index].webhook = e.target.value;
                            setArgs(updatedArgs);
                          }}
                        />
                      </div>

                      <p>
                        Add the variables required to run the Zap successfully.
                      </p>

                      <div>
                        {args[index].arguments.map((arg, argIndex) => (
                          <div key={argIndex}>
                            <Input
                              type="text"
                              className='texttt'
                              value={arg.arg}
                              placeholder="Key"
                              onChange={(e) => {
                                const updatedValue = e.target.value.replace(/[^a-zA-Z_]/g, ''); // Remove characters other than letters and underscores
                                const updatedArgs = [...args];
                                updatedArgs[index].arguments[argIndex].arg = updatedValue;
                                setArgs(updatedArgs);
                              }}
                            />
                            <Input
                              type="text"
                              className='texttt'
                              value={arg.value}
                              placeholder="Value"
                              onChange={(e) => {
                                const updatedArgs = [...args];
                                updatedArgs[index].arguments[argIndex].value = e.target.value;
                                setArgs(updatedArgs);
                              }}
                            />

                            <Button onClick={() => handleDeleteArgument(index, argIndex)} type="danger">
                              Delete
                            </Button>
                          </div>
                        ))}
                      </div>

                      <div>
                        <Input
                          type="text"
                          className='texttt'
                          value={keyInput}
                          placeholder="Key"
                          onChange={(e) => {
                            const updatedValue = e.target.value.replace(/[^a-zA-Z_]/g, ''); // Remove characters other than letters and underscores
                            setKeyInput(updatedValue);
                          }}
                        />
                        <Input
                          type="text"
                          className='texttt'
                          value={valueInput}
                          placeholder="Value"
                          onChange={(e) => setValueInput(e.target.value)}
                        />
                        <Button onClick={() => handleAddArguments(index)} type="primary" disabled={keyInput === "" || valueInput === ""}>+</Button>

                        <div className='save-button'>
                          <Button className='buttonz' onClick={() => handleSaveButton(automation)} type="primary">
                            Save
                          </Button>
                          <Button className='buttonz' onClick={() => handleDeleteButton(automation)} type="secondary">
                            Delete
                          </Button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>


          <h1>Subscription</h1>
          <p className='margin'>You are billed based on number of emails received each month where the AI is enabled. You do not incur additional fees by turning on automations.</p>
          <a href='https://billing.stripe.com/p/login/3csaIj88Dcrn8j63cc' target='_blank'>Manage subscription</a>

          <h1>TEST MODE</h1>
          <p>Will send the AI's logic in internal notes to Gorgias.</p>
          <br></br>
          <Switch
            style={lockSettings}
            checkedChildren=""
            unCheckedChildren=""
            checked={testMode}
            onChange={handleToggleTestMode}
          />

          <br></br><br></br>

          <div className='faq' style={lockSettings}>
            <h4 className='title-2'>Brand name</h4>
            <p>This is how we'll refer to ourselves in emails.</p>
            <TextArea
              rows={1}
              placeholder="Kylie Skin"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
            <Button className='faq-save' onClick={() => handleSaveButtonClick('name')}>Save</Button>
          </div>


          <div className='faq' style={lockSettings}>
            <h2 className='title-2'>Policy</h2>
            <p>Include ALL data a team would need to answer all FAQ inquiries</p>
            <TextArea
              rows={12}
              placeholder="Kylie Cosmetics - Customer Policy

                        Brand Overview:
                        Kylie Cosmetics is a leading makeup brand founded by Kylie Jenner. Our mission is to provide high-quality cosmetics that empower individuals to express their unique beauty and confidence. We value our customers and strive to create a positive and seamless shopping experience.
                        
                        Tracking Inquiries and Cases:
                        If you have inquiries about your order's tracking information or encounter any issues with tracking, our dedicated customer support team is here to assist you. Simply reach out to our support channels via email or phone, providing your order details, and we'll promptly investigate and update you on the status.
                        
                        Refunds and Returns:
                        We want you to be completely satisfied with your purchase. If you are not delighted with your Kylie Cosmetics product, please contact our support within 30 days of receiving the order. We will guide you through the return process, and upon receiving the returned item(s) in their original condition, we will issue a refund to your original payment method.
                        
                        Cancellations and Modifications:
                        Should you wish to cancel or modify your order, kindly contact us as soon as possible. We'll do our best to accommodate your request before the order is processed for shipment. Please note that once an order has been dispatched, cancellations and modifications may not be possible.
                        
                        Subscriptions:
                        Our subscription service allows you to receive your favorite Kylie Cosmetics products regularly. You have the flexibility to manage your subscription preferences, including delivery frequency, product selection, and billing information, through your online account. You can easily pause, skip, or cancel your subscription at any time.
                        
                        Product Concerns:
                        At Kylie Cosmetics, we take product quality seriously. If you experience any issues with the products you receive, such as damages or defects, please notify us within 7 days of receipt. We will gladly replace or refund any faulty items.
                        
                        Lost or Missing Packages:
                        In the rare event that your package is lost or missing during transit, please inform us immediately. We will initiate an investigation with the shipping carrier and work to resolve the issue promptly. If the package is confirmed lost, we will either resend your order or issue a full refund.
                        
                        Privacy and Security:
                        Kylie Cosmetics values your privacy and ensures that all customer data is treated with the utmost confidentiality and security. We do not share personal information with third parties, except as required for order fulfillment and customer support.
                        
                        Customer Feedback:
                        We welcome your feedback and suggestions. Your input helps us improve our products and services. Feel free to share your thoughts through our website, social media, or customer support channels.
                        
                        Thank you for choosing Kylie Cosmetics. We are committed to delivering the best beauty experience and appreciate your continued support."
              value={policy}
              onChange={(e) => setPolicy(e.target.value)}
            />
            <Button className='faq-save' onClick={() => handleSaveButtonClick('policy')}>Save</Button>
          </div>


          <div className='faq' style={lockSettings}>
            <h4 className='title-2'>FAQ's</h4>
            <p>Our system has been trained to avoid making stuff up at all costs. Because of this, you need to be sure to give the AI the information it needs.</p>
            <TextArea
              rows={12}
              placeholder="Any questions that refer to functionality that needs to be done by our system needs to be in the policy
                        section not the FAQ section. This section is purely for questions that are not related to inquiring about things like
                        tracking, refunds, returns etc. which means its more about shipping times, product questions, and just about anything else.
                        If you find it's not doing a good job at answering FAQ inquiries you should try adding more information here, making it more clear,
                        or making it more concise."
              value={faqNew}
              onChange={(e) => setFaqNew(e.target.value)}
            />
            <Button className='faq-save' onClick={() => handleSaveButtonClick('faqs')}>Save</Button>
          </div>


          <div className='faq' style={lockSettings}>
            <h4 className='title-2'>Tone</h4>
            <p>This is OPTIONAL. We write all emails in a friendly and professional tone, but some may require more customization.</p>
            <TextArea
              rows={6}
              placeholder="Try to end all emails in 'May the fourth be with you' if it makes sense give the context of the email."
              value={tone}
              onChange={(e) => setTone(e.target.value)}
            />
            <Button className='faq-save' onClick={() => handleSaveButtonClick('tone')}>Save</Button>
          </div>




          <div className="clients-section">
          <br></br>
          <br></br>
          <h2>Clients</h2>
          <Input
            placeholder="Enter client name"
            value={newClientName}
            onChange={(e) => setNewClientName(e.target.value)}
            style={{ width: 200, marginRight: '10px' }}
          />
          <Button type="primary" onClick={handleCreateClient}>
            Create Client
          </Button>

          {clients.map((client) => (
            <div key={client.id} className="client-item">
              {editingClientId === client.id ? (
                <>
                  <Button type="primary" onClick={() => handleSaveClient(client.id)}>
                    Save
                  </Button>
                  <Button type="danger" onClick={() => handleDeleteClient(client.id)}>
                    Delete Client
                  </Button>
                  {client.brandIds.map((brandId) => (
                    <Button
                      key={brandId}
                      type="link"
                      onClick={() => handleRemoveBrandFromClient(client.id, brandId)}
                    >
                      Remove {getBrandName(brandId)}
                    </Button>
                  ))}
                  {brands && (
                    <>
                      <Select
                        mode="multiple"
                        defaultValue={[]}
                        style={{ width: '80%', marginTop: '10px' }}
                        value={selectedBrands}
                        onChange={handleChange2}
                        options={[
                          { value: 'all', label: 'All' },
                          ...filteredBrands.map((brand) => ({
                            value: brand.id,
                            label: brand.name,
                          })),
                        ]}
                      />
                      <Button
                        type="primary"
                        onClick={() => handleAddBrandsToClient(client.id)}
                        style={{ marginTop: '10px' }}
                      >
                        Add Brands to Client
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <h3>{client.name}</h3>
                  <Button type="link" onClick={() => handleEditClient(client.id)}>
                    Edit
                  </Button>
                  {client.brandIds.map((brandId) => (
                    <p key={brandId}>{getBrandName(brandId)}</p>
                  ))}
                </>
              )}
            </div>
          ))}
          <br></br>
        </div>


          <div className='apikeys'>
            <h1>Shopify API Keys</h1>
            <p className="drop-text">Integrate Shopify so we can retrieve data and send refunds, cancellations, etc. automatically. An extremely simple and easy-to-follow tutorial is <a href="https://chirpish.notion.site/Chirpish-Onboarding-4d05dcb29fba45b5b72aa8d0a0d82752" className="hyperlink" target="_blank">here</a>.</p>
            {!showCode && (
              <Button className='faq-save' onClick={handleShowCodeClick}>
                Show API Keys
              </Button>
            )}
            {showCode && (
              <>
                <div className='keys'>
                  {generateInput('Shopify Name', "Ex. if https://apple.myshopify.com or https://admin.shopify.com/store/apple/ you'd input apple (Needs to be exact!)", shopifyName, setShopifyName)}
                  {generateInput('Shopify API Key', '48gjekdvunw4939wog9enbn4ep99jg9j', shopifyApiKey, setShopifyApiKey)}
                  {generateInput('Shopify Secret Key', '8561c0971844e9nvdojfijdroijw949hehb', shopifyPrivateKey, setShopifyPrivateKey)}
                  {generateInput('Shopify Admin API Access Token', 'shpat_b4nkrenvoirjfoidnrtbdifgnionn593jjgjf0', shopifyAdmin, setShopifyAdmin)}

                  <Button className='faq-save' onClick={saveKeys} disabled={saveButtonDisabled}>Save</Button>
                </div>
              </>
            )}
          </div>


          {/* <div className='apikeys'>
            <h1>Gorgias API keys</h1>
            <p className="drop-text">Integrate Gorgias for the account the brand is managed under.</p>
            {!showCodeGorgias && (
              <Button className='faq-save' onClick={handleShowCodeClickGorgias}>
                Show API Keys
              </Button>
            )}
            {showCodeGorgias && (
              <>
                <div className='keys'>
                  {generateInput('Gorgias name', "chirpish.gorgias.com would be: chirpish", gorgiasName, setGorgiasName)}
                  {generateInput('Gorgias email', 'Your profile email for Gorgias', gorgiasEmail, setGorgiasEmail)}
                  {generateInput('Gorgias API Key', 'Settings > REST API > API Key', gorgiasApi, setGorgiasApi)}

                  <Button className='faq-save' onClick={saveKeysGorgias} disabled={saveButtonDisabledGorgias}>Save</Button>
                </div>
              </>
            )}
          </div> */}


          <div className='apikeys'>
            <h1>17Track API key</h1>
            <p className="drop-text">Integrate with 17 Track to provide the most accurate shipping details to your customer.</p>
            {!showCodeTrack && (
              <Button className='faq-save' onClick={handleShowCodeClickTrack}>
                Show API Keys
              </Button>
            )}
            {showCodeTrack && (
              <>
                <div className='keys'>
                  <p className='key-title'>17Track API Key</p>
                  <Input.Password
                    placeholder={'Enter your 17Track API key'}
                    defaultValue={trackKey}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    onChange={(e) => setTrackKey(e.target.value)}
                  /><br /><br />
                  <Button className='faq-save' onClick={saveKeysTrack} disabled={!trackKey}>Save</Button>
                </div>
              </>
            )}
          </div>


          {currentUser.uid === 'ejgTdfrqPDUra3KeH3onWs8WN723' || currentUser.uid === 'ot0JlIiySgUffpMu41MLqBFKDZr1' || currentUser.uid === 'MQQtQ0sBv3aUYeUiTY4hjSlpNYG3' || currentUser.uid === 'wsSsZycZkuMs99yLLn6KCsmLfx93' || currentUser.uid === '98EBbNw3JrYtn6Ffh3VLcqhyIHf1' || currentUser.uid === 'xNsP3w5QhmdlVtmIKj9nOcIZPVv2' ? (
            <div className='apikeys'>
              <h1>Create Authorization Key</h1>
              <p className="drop-text">These Authorization keys authenticate requests from your Gorgias CRM.</p>

              {authorizationKey ? (
                <>
                  <br></br>
                  <p>The authorization key is: {authorizationKey}</p>
                </>
              ) :
                <>
                  {!showCodeChirpish && (
                    <Button className='faq-save' onClick={handleShowCodeClickChirpish}>
                      Show API Key
                    </Button>
                  )}

                  {showCodeChirpish && (
                    <>
                      <div className='keys'>
                        <p className='key-title'>Gorgias Domain</p>
                        <Input
                          placeholder="chirpish"
                          value={gorgiasDomain}
                          onChange={(e) => setGorgiasDomain(e.target.value)}
                        /><br /><br />
                        <p className='key-title'>Gorgias Admin Email</p>

                        <Input
                          placeholder="cael@chirpish.co"
                          value={gorgiasAdminEmail}
                          onChange={(e) => setGorgiasAdminEmail(e.target.value)}
                        /><br /><br />
                        <p className='key-title'>Gorgias API Key</p>

                        <Input
                          placeholder="382904t89h828h92hg9h8294hg334hg04h3983hg4389hg"
                          value={gorgiasApiKey}
                          onChange={(e) => setGorgiasApiKey(e.target.value)}
                        /><br /><br />
                        <Button className='faq-save' onClick={saveChirpishApi} disabled={!gorgiasDomain || !gorgiasAdminEmail || !gorgiasApiKey}>Create key</Button>
                      </div>
                    </>
                  )}
                </>
              }
            </div>
          ) : null}

          <br></br>
          <br></br>
          <br /><br />
          <br /><br />
        </div>
      </div>
    </>
  )
}



function generateInput(key, placeholder, value, setValue) {
  return (
    <>
      <p className='key-title'>{key}</p>
      <Input.Password
        placeholder={placeholder}
        defaultValue={value}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        onChange={(e) => setValue(e.target.value)}
      /><br /><br />
    </>
  );
}